<script setup>
import { ref, onMounted, watch } from 'vue'
import PreLoader from './PreLoader.vue';
 
 
//const show = ref(false)
 
const props = defineProps(['show'])
 
//console.log(props.show)

const shouldRender = ref(false);


</script>
 
 
<template>

 
 <transition name="fade" appear>
<div v-if="!props.show" class="whitespace"></div>
</transition>
 
</template>
 
 
<style lang="scss" scoped>


 
.whitespace{
  background-color:white;
    width:100%;
    position:absolute;
    z-index: 1;
    display:flex;
    justify-content:center;
    flex-direction:column;
    bottom: 72px;
    top: 64px;
    box-shadow: inset 0px -2px 8px 2px rgba(0, 0, 0, 0.06);
    left: 0px;
    right: 0px;
    
    @media screen and (min-width: 481px) and (max-width: 900px){
      bottom: 64px;
      top: 65px;
    }
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      bottom: 64px;
      top: 44px;
    }
}
 
 
</style>